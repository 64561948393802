<template>
  <div class="login-page">
    <div class="wrapper">
      <div class="container">
        <div class="row">
          <div class="col-xl-4 offset-xl-4 col-lg-6 offset-lg-3 col-md-8 offset-md-2">
            <div class="login__header">
              <div class="login__header--logo">
                <a href="/">
                  <img src="../../assets/img/logo-login.svg" alt="" />
                </a>
              </div>
              <!-- <div class="login__header--lang">
                <div class="header__leng">
                  <ul>
                    <li class="header__lang--item"><a href="#">kz</a></li>
                    <li class="header__lang--item header__lang--active">
                      <a href="#">ru</a>
                    </li>
                   
                  </ul>
                </div>
              </div> -->

              <div class="login__form">
                <form @submit.prevent="recovery()" id="logint_form">
                  <div class="form__block--form">
                    <div class="form__block--subtitle">Создать пароль</div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form__login--line">
                          <div class="form__login--input">
                            <label>
                              <div class="label__login--icon">
                                <img src="../../assets/img/mail_login-icon.svg" />
                              </div>
                              <input
                                type="text"
                                v-model="email"
                                class="input-linck"
                                required=""
                                name="email"
                                value=""
                                placeholder="Ваш email"
                              />
                            </label>
                            <span class="input-required" v-if="emailError"
                              >Неверное имя пользователя или пароль!</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="form__login--line">
                          <div class="form__login--input">
                            <label>
                              <div class="label__login--icon">
                                <img
                                  src="../../assets/img/password_login-v-icon.svg"
                                  v-if="inputType"
                                  @click="inputType = false"
                                />
                                <img
                                  src="../../assets/img/password_login-icon.svg"
                                  v-else
                                  @click="inputType = true"
                                />
                              </div>
                              <input
                                type="password"
                                v-if="!inputType"
                                class="input-linck"
                                v-model="password"
                                required=""
                                name="password"
                                value=""
                                placeholder="Пароль"
                              />
                              <input
                                type="text"
                                v-else
                                class="input-linck"
                                v-model="password"
                                required=""
                                name="password"
                                value=""
                                placeholder="Пароль"
                              />
                            </label>
                            <span class="input-required" v-if="passwordError"
                              >Не верный пароль!</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-12">
                        <div class="form__login--input">
                          <button class="input__button">Востановить</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inputType: false,
      email: "",
      password: "",
      emailError: false,
      passwordError: false,
    };
  },
  methods: {
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    recovery() {
      if (this.email == "") {
        this.emailError = true;
        return false;
      }
      if (this.validateEmail(this.email) == false) {
        this.emailError = true;
        return false;
      }
      this.$axios({
        method: "post",
        url:
          this.$API_URL +
          this.$API_VERSION +
          "/password/reset/" +
          this.$route.params.hash,
        data: {
          email: this.email,
          password: this.password,
          token: this.$route.params.hash,
          type: this.$route.params.type,
        },
      })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          let errors = error.response.data.errors;
          for (let variable in errors) {
            console.log(variable);
            this.$toast.open({
              message: errors[variable][0],
              type: "error",
              position: "bottom",
              duration: 4000,
              queue: true,
            });
            continue;
          }
          console.log(error.response.data.message);
          if (error.response.data.message) {
            this.$toast.open({
              message: error.response.data.message,
              type: "error",
              position: "bottom",
              duration: 4000,
              queue: true,
            });
          }
        });
    },
  },
  mounted() {},
  watch: {},
};
</script>
